import React from "react";
import { Link } from "react-router-dom";

import topbarLogo from "Assets/images/medical-alert-logo.png";
import closeIcon from "Assets/images/close-icon.svg";
import loginIcon from "Assets/images/user.svg";

function MobileMenu({ onCloseMobMenu, isLoggedIn, logoutHandler }) {
  const isNew = localStorage.getItem("is_new_user") === "1";

  return (
    <div className="mobile-menu" onClick={onCloseMobMenu}>
      <div className="mobile-menu-header">
        <Link className="linkToPage" to="/home">
          <img
            className="company-logo"
            src={topbarLogo}
            alt="Medical Alert Logo"
          />
        </Link>
        <img className="close-btn" src={closeIcon} alt="Close Icon" />
      </div>
      <div className="mobile-menu-body">
        <ul className="mobile-menu-list">
          {!isLoggedIn() ? (
            <>
              <li>
                <Link className="linkToPage" to="/about">
                  Our Story
                </Link>
              </li>
              <li>
                <Link className="linkToPage" to="/meet-our-board">
                  Meet Our Board
                </Link>
              </li>
              <li>
                <Link className="linkToPage" to="/endorsement">
                  Our Endorsers
                </Link>
              </li>
              <li>
                <Link className="linkToPage" to="/membership">
                  Features and Benefits
                </Link>
              </li>
              <li>
                <Link className="linkToPage" to="/collaboration-partners">
                  Collaboration Partners
                </Link>
              </li>
              <li>
                <Link className="linkToPage" to="/code-of-conduct">
                  Code of Conduct
                </Link>
              </li>
              <li>
                <Link className="linkToPage" to="/training-&-support">
                  Training & Support
                </Link>
              </li>
              <li>
                <Link className="linkToPage" to="/postage-&-shipping">
                  Postage & Shipping
                </Link>
              </li>
              <li>
                <Link className="linkToPage" to="/privacy">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link className="linkToPage" to="/testimonials">
                  Testimonials
                </Link>
              </li>
              <li>
                <Link className="linkToPage" to="/donate">
                  Donate
                </Link>
              </li>
              <li>
                <Link className="linkToPage" to="/faq">
                  FAQ
                </Link>
              </li>
              <li className="primary-color">
                <Link to="/contact" className="linkToPage primary-color mb-3">
                  Contact Us
                </Link>
                <a style={{ display: "block" }} href="tel:+60184710020">
                  +60184710020
                </a>
                <a
                  style={{ display: "block" }}
                  href="mailto:support@medicalert.org.my"
                >
                  support@medicalert.org.my
                </a>
              </li>
              <li>
                <Link className="login-button linkToPage" to="/login">
                  <img
                    width={30}
                    src={loginIcon}
                    style={{ marginRight: "20px" }}
                    alt="user icon"
                  />
                  Log In
                </Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <div className="login-menu-top">
                  <Link className="linkToPage" to="/account">
                    My Account
                  </Link>
                  {!isNew && (
                    <Link className="linkToPage" to="/profile">
                      My Profile
                    </Link>
                  )}
                  <div className="primary-color">
                    <Link
                      to="/contact"
                      className="linkToPage primary-color mb-3"
                    >
                      Contact Us
                    </Link>
                    <a style={{ display: "block" }} href="tel:+60184710020">
                      +60184710020
                    </a>
                    <a
                      style={{ display: "block" }}
                      href="mailto:support@medicalert.org.my"
                    >
                      support@medicalert.org.my
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="logout-button secondary-btn"
                  onClick={() => logoutHandler()}
                >
                  Log Out
                </div>
              </li>
              <li>
                <Link
                  className="login-button linkToPage donate-button"
                  to="/donate"
                >
                  Donate
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

export default MobileMenu;
